import styles from './Rodo.module.scss'
import HelmetContent from "../../../helpers/HelmetContent";

const InformationalInformationWebsite = () => {
    return(
        <>
            <HelmetContent noindex={true} />
            <div className={styles.informationalInformationWebsite}>
                <p>
                    W związku z rozpoczęciem obowiązywania z dniem 25 maja 2018 r. Rozporządzenia
                    Parlamentu Europejskiego Rady (UE) 2016/679 z dnia 27 kwietnia 2016 r. w sprawie
                    ochrony osób fizycznych w związku z przetwarzaniem danych osobowych i w sprawie
                    swobodnego przepływu takich danych oraz uchylenia dyrektywy 95/46/WE (ogólne
                    rozporządzenie o ochronie danych), informuję Panią/Pana, że:
                </p>
                <h6>
                    1. Administratorem Pani/Pana danych osobowych jest Monika Juczyńska Hallux Clinic z
                    siedzibą w Łodzi przy ul. Armii Krajowej 44/13, 94-046 Łódź NIP7261496883
                    Administratorem danych osobowych można skontaktować się pod adresem mailowym
                    mjuczynska@interia.pl
                </h6>
                <h6>
                    2. Pani/Pana dane osobowe przetwarzane są w celu udzielania świadczeń/usług
                    zdrowotnych, w tym prowadzenia dokumentacji medycznej oraz zarządzania procesem
                    udzielania świadczeń/usług na podstawie:
                </h6>
                <ul>
                    <li>
                        - Art. 6 ust. 1 lit. b oraz Art. 9 ust. 2 lit. a RODO w przypadku usług medycznych lub zabiegów w
                        celu wykonania tych usług;
                    </li>
                    <li>
                        - Art. 6 ust. 1 lit. f RODO w celu realizacji prawnie uzasadnionych interesów Administratora w
                        szczególności telefonicznego potwierdzenia lub odwołania wizyty.
                    </li>
                    <li>
                        - Art. 6 ust. 1 lit. c RODO w zw. z art. 74 ust. 2 ustawy z dnia 29 września 1994 r. o
                        rachunkowości w celu przetwarzania dokumentacji księgowo – podatkowej w tym: prowadzenie
                        ksiąg rachunkowych, wystawianie rachunków za wykonane usługi co może wiązać się z
                        koniecznością przetwarzania danych osobowych.
                    </li>
                    <li>
                        - Art. 6 ust. 1 lit. f RODO w celu dochodzenia roszczeń względem Administratora oraz
                        dochodzenia i ochrony przed roszczeniami Administratora, przy czym uzasadniony interes
                        Administratora oraz Pani/Pana wynikają z innych przepisów prawa (m.in. kodeks cywilny);
                    </li>
                </ul>
                <h6>
                    3. Podanie przez Panią/Pana danych osobowych jest wymogiem ustawowym. Jest
                    Pani/Pan zobowiązana/y do ich podania. Konsekwencją niepodania danych osobowych
                    będzie brak możliwości udzielania usług medycznych lub zabiegów w tym prowadzenia
                    dokumentacji dotyczącej usługi medycznej oraz zarządzania procesem udzielania
                    świadczeń. W przypadku usług medycznych oraz zabiegów podanie danych osobowych
                    jest dobrowolne, ale niezbędne do skutecznej realizacji celu, jakim jest wykonywanie
                    tych usług. Konsekwencją niepodania danych będzie brak możliwości wykonania usług.
                    Podanie numeru telefonu, adresu e mail jest dobrowolne a jego niepodanie będzie
                    skutkowało brakiem możliwości odwołania wizyty w sytuacjach tego wymagających;
                </h6>
                <h6>
                    4. Pani/Pana dane osobowe przetwarzane są również w celu marketingowym – tylko na
                    podstawie wyrażonej przez Panią/Pana dodatkowej zgody. Podstawą prawną
                    przetwarzania danych jest art. 6 ust. 1 lit. a) ogólnego rozporządzenia o ochronie danych
                    (zgoda osoby, której dane dotyczą). Wyrażenie zgody na przetwarzanie Pani/Pana
                    danych jest dobrowolne. Niewyrażenie zgody nie ma wpływu na realizację usług i ich
                    jakość. Ma Pani/Pan prawo do cofnięcia zgody w dowolnym momencie bez wpływu na
                    zgodność z prawem przetwarzania, którego dokonano na podstawie zgody przed jej
                    cofnięciem. Zgodę może Pani/Pan odwołać w formie pisemnej wysłanej na adres
                    siedziby Administratora lub mu bezpośrednio doręczonej. Podanie przez Panią/Pana
                    danych jest dobrowolne. Konsekwencją niepodania danych będzie brak możliwości
                    otrzymywania przez Panią/Pana informacji handlowych.
                </h6>
                <h6>
                    5. Posiada Pani/Pan prawo dostępu do treści swoich danych i ich sprostowania, w tym
                    poprawiania i uzupełniania, usunięcia lub ograniczenia przetwarzania oraz prawo
                    wniesienia skargi do organu nadzorczego zajmującego się ochroną danych osobowych;
                </h6>
                <h6>
                    6. Dane będą przechowywane przez wymagany przepisami prawa okres przechowywania
                    dokumentacji dotyczącej usługi (co najmniej 6 lat) i dokumentacji księgowo podatkowej
                    (5 lat) oraz okres przedawnienia roszczeń wynikający z kodeksu cywilnego; w
                    przypadku przetwarzania w celu marketingowym do momentu odwołania przez
                    Panią/Pana zgody;
                </h6>
                <h6>
                    7. Odbiorcami Pani/Pana danych osobowych mogą być podmioty przetwarzające dane
                    osobowe na zlecenie Administratora: dostawcy usług IT, księgowych, doradczych,
                    kurierskich i pocztowych, medycznych;
                </h6>
                <h6>
                    8. Pani/Pana dane osobowe nie będą przetwarzane w sposób zautomatyzowany i nie będą
                    poddawane profilowaniu; w trakcie przetwarzania danych osobowych nie dochodzi do
                    wyłącznie zautomatyzowanego podejmowania decyzji ani do profilowania, o których
                    mowa w art. 22 ust. 1 i 4 RODO. Oznacza to, że wobec osoby, której dane dotyczą, żadne
                    decyzje nie będą zapadały wyłącznie automatycznie oraz że nie buduje się żadnych
                    profili takich osób.
                </h6>
                <h6>
                    9. Pani/Pana dane będą udostępniane podmiotom zaufanym na zasadach określnych w
                    polityce prywatności i polityce cookies.
                </h6>
            </div>
        </>
    )
}

export default InformationalInformationWebsite;