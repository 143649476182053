import styles from "./Rodo.module.scss";
import HelmetContent from "../../../helpers/HelmetContent";

const InformationalInformationCart = () => {
    return(
        <>
            <HelmetContent noindex={true} />
            <div className={styles.informationalInformationCart}>
                <p>
                    Na podstawie RODO - rozporządzenia Parlamentu Europejskiego i Rady (UE) 2016/679
                    z dnia 27 kwietnia 2016 r. w sprawie ochrony osób fizycznych w związku
                    z przetwarzaniem danych osobowych i w sprawie swobodnego przepływu takich danych
                    oraz uchylenia dyrektywy 95/46/WE (ogólnego rozporządzenia o ochronie danych)
                    informujmy, że:
                </p>
                <h6>
                    1. Administratorem Pani/Pana danych osobowych jest Monika Juczyńska Hallux Clinic z
                    siedzibą w Łodzi przy ul. Armii Krajowej 44/13, 94-046 Łódź NIP7261496883
                    Z Administratorem danych osobowych można skontaktować się pod adresem mailowym
                    mjuczynska@interia.pl
                </h6>
                <h6>
                    2. Pani/Pana dane osobowe przetwarzane są w celu udzielania usługi, w tym prowadzenia
                    dokumentacji dotyczącej zabiegu oraz zarządzania procesem udzielania usługi na
                    podstawie:
                </h6>
                <ul>
                    <li>
                        - Art. 6 ust. 1 lit. b oraz art. 9 ust. 2 lit. a RODO w celu ustalenia tożsamości przed udzieleniem
                        świadczenia, w szczególności weryfikacji danych podczas telefonicznego i osobistego umawiania
                        wizyty oraz w celu prowadzenia i przechowywania dokumentacji jej potwierdzającej
                    </li>
                    <li>
                        - Art. 6 ust. 1 lit. f RODO w celu realizacji prawnie uzasadnionych interesów Administratora
                        w szczególności telefonicznego potwierdzenia lub odwołania wizyty.
                    </li>
                    <li>
                        - Art. 6 ust. 1 lit. c RODO w zw. z art. 74 ust. 2 ustawy z dnia 29 września 1994 r.
                        o rachunkowości w celu przetwarzania dokumentacji księgowo – podatkowej w tym:
                        prowadzenie ksiąg rachunkowych, wystawianie rachunków za wykonane usługi co może wiązać
                        się z koniecznością przetwarzania danych osobowych.
                    </li>
                    <li>
                        - Art. 6 ust. 1 lit. f RODO w celu dochodzenia roszczeń względem Administratora oraz
                        dochodzenia i ochrony przed roszczeniami Administratora, przy czym uzasadniony interes
                        Administratora oraz Pani/Pana wynikają z innych przepisów prawa (m.in. kodeks cywilny);
                    </li>
                </ul>
                <h6>
                    3. Podanie przez Panią/Pana danych osobowych nie jest wymogiem ustawowym.
                    Konsekwencją niepodania danych osobowych będzie brak możliwości udzielania
                    świadczeń zdrowotnych, w tym prowadzenia dokumentacji dotyczącej usługi medycznej
                    oraz zarządzania procesem udzielania świadczeń. Podanie numeru telefonu, adresu e-
                    mail jest dobrowolne a jego niepodanie będzie skutkowało brakiem możliwości
                    odwołania wizyty w sytuacjach tego wymagających;
                </h6>
                <h6>
                    4. Posiada Pani/Pan prawo dostępu do treści swoich danych i ich sprostowania, w tym
                    poprawiania i uzupełniania, usunięcia lub ograniczenia przetwarzania oraz prawo
                    wniesienia skargi do organu nadzorczego zajmującego się ochroną danych osobowych;
                </h6>
                <h6>
                    5. Dane będą przechowywane przez wymagany przepisami prawa okres przechowywania
                    dokumentacji dotyczącej usługi medycznej (co najmniej 6 lat) i dokumentacji księgowo-
                    podatkowej (5 lat) oraz okres przedawnienia roszczeń wynikający z kodeksu cywilnego;
                </h6>
                <h6>
                    6. Odbiorcami Pani/Pana danych osobowych mogą być podmioty przetwarzające dane
                    w naszym imieniu na podstawie umowy powierzenia przetwarzania danych oraz
                    podmioty, którym zobowiązani jesteśmy udostępniać dane na podstawie przepisów
                    prawa;
                </h6>
                <h6>
                    7. w trakcie przetwarzania danych osobowych nie dochodzi do wyłącznie
                    zautomatyzowanego podejmowania decyzji ani do profilowania, o których mowa w art.
                    22 ust. 1 i 4 RODO. Oznacza to, że wobec osoby, której dane dotyczą, żadne decyzje nie
                    będą zapadały wyłącznie automatycznie oraz że nie buduje się żadnych profili takich
                    osób.
                </h6>
                <h6>
                    8. Pani/Pana dane nie będą przekazywane poza Europejski Obszar Gospodarczy ani
                    udostępniane organizacjom międzynarodowym.
                </h6>
            </div>
        </>
    )
}

export default InformationalInformationCart;